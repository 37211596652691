import { CATEGORY_WITH_CONTEXT_FRAGMENT } from '@/graphql/_Fragments/Category/WithContext';
import { SPEAKER_ROLE_FOR_SESSION_FRAGMENT } from '@/graphql/_Fragments/SpeakerRole/ForSession';

export const SCHEDULE_EVENT_FULL_FRAGMENT = `
  fragment scheduleEventFullFragment on ScheduleEvent {
    uid
    schemaCode
    type
    name
    description
    url
    urlLabel
    startTime
    startTimestamp
    endTime
    endTimestamp
    childSessionsOrderBy
    childSessionsFilter
    session {
      uid
      name
      startTime
      startTimestamp
      endTime
      endTimestamp
      categoriesInContext(context:"session_flag") {
      ...categoryWithContextFragment
      }
      speakerRoles {
        ...speakerRoleForSessionFragment
      }
      geozonesOfEdition: _geozonesOfEdition {
        exhibitHall {
          name
        }
      }
      _isInAgenda(myUid: "%authUser%")
    }
    childSessions(orderBy: startTimestamp_asc) {
      uid
      name
      startTime
      startTimestamp
      endTime
      endTimestamp
      geozonesOfEdition: _geozonesOfEdition {
        exhibitHall {
          name
        }
      }
      _isInAgenda(myUid: "%authUser%")
    }
    _isInAgenda(myUid: "%authUser%")
  }
  ${CATEGORY_WITH_CONTEXT_FRAGMENT}
  ${SPEAKER_ROLE_FOR_SESSION_FRAGMENT}
`;
